import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom'; 
import './NavBar.css';

function NavBar() {
  const [hideTitle, setHideTitle] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showGalleryDropdown, setShowGalleryDropdown] = useState(false); 
  const [dropdownTimer, setDropdownTimer] = useState(null);
  const [galleryDropdownTimer, setGalleryDropdownTimer] = useState(null); 
  const [showMangalyaDropdown, setShowMangalyaDropdown] = useState(false); 
  const navbarRef = useRef(null);
  const navigate = useNavigate(); 

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          const buffer = 100;
          if (currentScrollY > 200 + buffer && !hideTitle) {
            setHideTitle(true);
          } else if (currentScrollY < 200 - buffer && hideTitle) {
            setHideTitle(false);
          }

          const fbPageContainer = document.querySelector('.fb-page-container');
          const titleImage = document.querySelector('.title-image');

          if (titleImage && fbPageContainer) {
            const imageRect = titleImage.getBoundingClientRect();
            fbPageContainer.style.top = `${imageRect.top + window.scrollY}px`;
          }

          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [hideTitle]);

  const handleMouseEnter = (setDropdownState) => {
    if (dropdownTimer) {
      clearTimeout(dropdownTimer);
    }
    setDropdownState(true);
  };

  const handleMouseLeave = (setDropdownState, setTimerState) => {
    const timer = setTimeout(() => {
      setDropdownState(false);
    }, 300); 
    setTimerState(timer);
  };

  const handleScrollToSection = (sectionId) => {
    const element = document.querySelector(sectionId);
    if (element) {
      const navbarHeight = navbarRef.current ? navbarRef.current.offsetHeight : 0;
      window.scrollTo({
        top: element.offsetTop - navbarHeight, 
        behavior: 'smooth',
      });
    }
  };

  const handleLinkClick = (e, sectionId) => {
    e.preventDefault(); 
    if (sectionId) {
      
      navigate('/');
      setTimeout(() => {
        handleScrollToSection(sectionId);
      }, 100); 
    }
  };

  const handleServicesClick = (e) => {
    e.preventDefault();
    setShowMangalyaDropdown(!showMangalyaDropdown); // Toggle the visibility of the Mangalya dropdown
    if (!showMangalyaDropdown) {
      navigate('/mangalya'); // Redirect to the Mangalya page when the dropdown is closed
    }
  };
  
  

  const mangalyaList = [
    { title: 'Mangalya', path: '/mangalya' },
  ];

  const sevaList = [
    { title: 'Sri Datta Venkateshwara Seva', path: '/SdvtSevaDetails' },
    { title: 'Devi Padmavathi Seva', path: '/DeviPadmavathiSeva' },
    { title: 'Sachidananda Ganapathy Seva', path: '/SachidanandaGanapathySeva' },
    { title: 'Dhanvantri Seva', path: '/DhanvantriSeva' },
    { title: 'Marakatha Subrahmanya Swamy Seva', path: '/MarakathaSubrahmanyaSwamySeva' },
    { title: 'Sarva Dosha Hara Shivalaya Seva', path: '/SarvaDoshaHaraShivalayaSeva' },
  ];

  const galleryList = [
    { title: 'Sri Datta Venkateshwara Temple', path: '/SdvtGallery' },
    { title: 'Devi Padmavathi Sannidhi', path: '/DeviPadmavathiGallery' },
    { title: 'Sachidananda Ganapathy Sannidhi', path: '/SachidanandaGanapathyGallery' },
    { title: 'Dhanvantri Sannidhi', path: '/DhanvantariGallery' },
    { title: 'Marakatha Subrahmanya Sannidhi', path: '/MarakathaSubrahmanyaSwamyGallery' },
    { title: 'Sarva Dosha Hara Shivalaya Sannidhi', path: '/SarvaDoshaHaraShivalayaGallery' },
    { title: 'Karya Siddhi Hanuman Sannidhi', path: '/KaryaSiddhiHanumanGallery' },
  ];

  return (
    <header className={`navbar ${hideTitle ? 'hide-title' : ''}`} ref={navbarRef}>
      <div className="logo-title">
        <h1>Sri Datta Venkateswara Temple</h1>
        <h2>Mysore - Karnataka</h2>
      </div>
      <nav className="nav-links">
        <Link to="/" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>Home</Link>
        <div 
          className="dropdown"
          onMouseEnter={() => handleMouseEnter(setShowDropdown)}
          onMouseLeave={() => handleMouseLeave(setShowDropdown, setDropdownTimer)}
        >
          <Link to="#services" className='services-nav-item'>Seva</Link>
          {showDropdown && (
            <div className="dropdown-content">
              {sevaList.map((seva, index) => (
                seva.path ? (
                  <Link key={index} to={seva.path}>
                    {seva.title}
                  </Link>
                ) : (
                  <a key={index} href={seva.link} target="_blank" rel="noopener noreferrer">
                    {seva.title}
                  </a>
                )
              ))}
            </div>
          )}
        </div>
        <div 
          className="dropdown"
          onMouseEnter={() => handleMouseEnter(setShowGalleryDropdown)}
          onMouseLeave={() => handleMouseLeave(setShowGalleryDropdown, setGalleryDropdownTimer)}
        >
          <Link to="#gallery" className='services-nav-item'>Gallery</Link>
          {showGalleryDropdown && (
            <div className="dropdown-content">
              {galleryList.map((gallery, index) => (
                <Link key={index} to={gallery.path} onClick={() => navigate(gallery.path)}>
                  {gallery.title}
                </Link>              
              ))}
            </div>
          )}
        </div>
        <a href="#contact" onClick={(e) => handleLinkClick(e, '#footer')}>Contact</a>
        <Link to="/events">Events</Link>
        <a href="https://www.dattapeetham.org/donations?ashram_id=6&currency=inr">Donations</a>
        <div 
  className="dropdown"
  onMouseEnter={() => handleMouseEnter(setShowMangalyaDropdown)}
  onMouseLeave={() => handleMouseLeave(setShowMangalyaDropdown, setDropdownTimer)}
>
  <Link to="#" className='services-nav-item'>Services</Link>
  {showMangalyaDropdown && (
    <div className="dropdown-content">
      <Link to="/mangalya">Mangalya</Link>
    </div>
  )}
</div>
      </nav>
    </header>
  );
}

export default NavBar;
