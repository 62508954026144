import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import NavBar from './NavBar';
import MainContent from './MainContent';
import Footer from './Footer';
import SdvtSevaDetails from './seva/SdvtSevaDetails';
import DeviPadmavathiSeva from './seva/DeviPadmavathiSeva';
import SachidanandaGanapathySeva from './seva/SachidanandaGanapathySeva';
import DhanvantriSeva from './seva/DhanvantriSeva';
import MarakathaSubrahmanyaSwamySeva from './seva/MarakathaSubrahmanyaSwamySeva';
import SarvaDoshaHaraShivalayaSeva from './seva/SarvaDoshaHaraShivalayaSeva';
import KaryaSiddhiHanumanGallery from './gallery/KaryaSiddhiHanumanGallery';
import SdvtGallery from './gallery/SdvtGallery';
import DeviPadmavathiGallery from './gallery/DeviPadmavathiGallery';
import SachidanandaGanapathyGallery from './gallery/SachidanandaGanapathySwamyGallery';
import MarakathaSubrahmanyaSwamyGallery from './gallery/MarkathaSubrahmanyaSwamyGallery';
import SarvaDoshaHaraShivalayaGallery from './gallery/SarvaDoshaHaraShivalayaGallery';
import DhanvantariGallery from './gallery/DhanvantriGallery';
import Events from './Events';
import Notification from './Notification';
import Login from './Login';
import MangalyaRedirect from './MangalyaRedirect';

function App() {
  const isAdmin = true;
  const location = useLocation();

  // List of routes where the footer should not be displayed
  const noFooterRoutes = ['/mangalya'];

  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/" element={<MainContent />} />
        <Route path="/SdvtSevaDetails" element={<SdvtSevaDetails />} />
        <Route path="/DeviPadmavathiSeva" element={<DeviPadmavathiSeva />} />
        <Route path="/SachidanandaGanapathySeva" element={<SachidanandaGanapathySeva />} />
        <Route path="/DhanvantriSeva" element={<DhanvantriSeva />} />
        <Route path="/MarakathaSubrahmanyaSwamySeva" element={<MarakathaSubrahmanyaSwamySeva />} />
        <Route path="/SarvaDoshaHaraShivalayaSeva" element={<SarvaDoshaHaraShivalayaSeva />} />
        <Route path="/KaryaSiddhiHanumanGallery" element={<KaryaSiddhiHanumanGallery />} />
        <Route path="/SdvtGallery" element={<SdvtGallery />} />
        <Route path="/DeviPadmavathiGallery" element={<DeviPadmavathiGallery />} />
        <Route path="/SachidanandaGanapathyGallery" element={<SachidanandaGanapathyGallery />} />
        <Route path="/MarakathaSubrahmanyaSwamyGallery" element={<MarakathaSubrahmanyaSwamyGallery />} />
        <Route path="/SarvaDoshaHaraShivalayaGallery" element={<SarvaDoshaHaraShivalayaGallery />} />
        <Route path="/DhanvantariGallery" element={<DhanvantariGallery />} />
        <Route path="/Events" element={<Events />} />
        <Route path="/notifications" element={<Notification isAdmin={isAdmin} />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/mangalya" element={<MangalyaRedirect />} />
        {/* <Route path="/Register" element={<Register />} /> */}
      </Routes>

      {/* Conditionally render the Footer */}
      {!noFooterRoutes.includes(location.pathname) && <Footer />}
    </div>
  );
}

export default function RootApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
