import React, { useEffect } from 'react';
import './MangalyaRedirect.css';

function MangalyaRedirect() {
  // Use useEffect to update the title after the component has mounted
  useEffect(() => {
    document.title = "Mangalya Mandapam Information Page for SGS Devotees";
    console.log("Document title updated to:", document.title); // Log to confirm
  }, []); // Empty dependency array ensures this runs once when the component mounts

  return (
    <div className="iframe-container">
      <h2 className="page-title">Mangalya Mandapam Information Page for SGS Devotees</h2>
      <p className="info-message">This page is for the devotees of Sri Ganapathy Sachchidananda Ashrama, Mysore (Sri Datta Venkateswara Temple) only.</p>
      
      <iframe
        src="https://vedanidhi.in/mangalya/"
        title="Mangalya Page"
        className="responsive-iframe"
      />
    </div>
  );
}

export default MangalyaRedirect;
